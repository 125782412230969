<template>
  
  <div>
    <div class="card">
          <div class="card-header">
                <div class="card-title m-0">
                    <h3>Dosyalar</h3>
                </div>

          </div>

          <div class="card-body">
            <div>
                <a href="" @click.prevent="addNewFile()" data-bs-target="#file-upload-modal" data-bs-toggle="modal" class="mb-2 btn btn-primary">Yeni Dosya Ekle</a>
            </div>


            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
               <thead>
                   <tr class="fw-bold">
                       <th>Dosya adı</th>
                       <th>Başlık</th>                       
                       <th>Açıklama</th>
                       <th>Boyut</th>
                       <th>Tipi</th>
                       <th>İşlem</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for="(item, i) in packages" :key="i">
                       <td><a :href="fileLink(item)">{{item.fileName}}</a></td>
                       <td>{{item.fileTitle}}</td>
                       <td>{{item.fileDesc}}</td>
                       <td>{{humanFileSize(item.fileSize)}}</td>
                       <td>{{item.fileType}}</td>
                       <td>
                            <a @click.prevent="deleteFile(item)" class="file-delete btn btn-sm btn-danger">Sil</a>
                        </td>
                   </tr>
               </tbody>
           </table>

          </div>
    </div>


    <div class="modal" tabindex="-1" id="file-upload-modal">
      <div class="modal-dialog modal-fade modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dosya yükleme</h5>
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>

        <div class="modal-body">
          <div>
           
            <div>
                <div class="row mb-6">                  
                    <label class="col-lg-2 col-form-label fw-bold fs-6">Dosya</label>
                    <div class="col-lg-10">
                        <input type="file" name="Files" :accept="fileTypes" @change="uploadFile" ref="file" id="" class="form-control">
                    </div>
                </div>

                <div class="row mb-6">                  
                    <label class="col-lg-2 col-form-label fw-bold fs-6">Başlık</label>
                    <div class="col-lg-10">
                        <input type="text" name="filetitle" v-model="fileTitle" class="form-control" id="">
                    </div>
                </div>

                <div class="row mb-6">                  
                    <label class="col-lg-2 col-form-label fw-bold fs-6">Açıklama</label>
                    <div class="col-lg-10">
                        <textarea name="filedesc" id="" class="form-control" v-model="fileDesc" cols="30" rows="4"></textarea>
                    </div>
                </div>

            </div>
            

          </div>
        </div>

        <div class="modal-footer">

          <button class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close">Kapat</button>
          <button class="btn btn-success" @click.prevent="save()">Kaydet</button>
        </div>
      </div>
    </div>
  </div>



  </div>
  

</template>

<script>
import api from '@/core/services/ApiService'
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
    props:['profile'],
    data(){
        return {
            packages:null,
            fileTypes:[],
            //basePath:location.hostname=='backoffice.ytmdanismanlik.com' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_TEST_API_URL,
            basePath:'https://devboapi.ytmdanismanlik.com',
            Files:null,
            fileTitle:null,
            fileDesc:null,
        }
    },
        
    methods:{

        deleteFile(item){
            Swal.fire({
                icon:'warning',
                title:'Dosya Silme',
                text:item.fileTitle+' başlıklı dosyayı silmek istediğinize emin misiniz?',
                showCancelButton:true,
                cancelButtonText:'Vazgeç',
                confirmButtonText:'Evet'
            }).then((res)=>{
                if(res.isConfirmed){
                    
                    api.get('/File/Delete/'+item.fileId).then((response)=>{
                        Swal.fire({
                            title:'Dosya Silme',
                            text:'Dosya kullanıcıdan kaldırıldı',
                            icon:'success'
                        }).then(()=>{
                            document.location.reload();
                        })
                    })
                }
            })
        },

        uploadFile() {
            this.Files = this.$refs.file.files[0];
        },

        fileLink (file){
             return this.basePath+file.fileAddress+file.fileName;
        },

        humanFileSize(size) {
            
            var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
            return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
        },

        save(){
            let profile = this.profile;
            
            const formData = new FormData();
            formData.append('file', this.Files);
            formData.append('filetitle', this.fileTitle);
            formData.append('filedesc', this.fileDesc);
            formData.append('consultantid', profile.userId);
            

            api.post('/File/Upload',formData).then((res)=>{
                Swal.fire({
                    title:'Dosya Yükleme',
                    icon:'success',
                    text:'Dosya yüklemi işlemi tamamlandı'
                }).then(res=>{
                    document.location.reload();
                })
                
            }).catch((err)=>{
                Swal.fire({
                    title:'Dosya Yükleme',
                    text:'Dosya Yükleme Hatası'+JSON.stringify(err),
                    icon:'danger'
                })
            })
        }
    },

    mounted(){            
            api.get('/File/List/0/'+this.profile.userId).then((res)=>{
                this.packages = res.data;                
            });

            api.get('/File/Type/List').then(res=>{
                this.fileTypes = res.data.map(item=>{return item.mimeType});

            })
        }

    

}
</script>

<style>

</style>